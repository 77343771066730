import initialState from '../_helpers/initialState'
import api from '@/services/api_v2'
import { WAITING_FOR_SIGNATURE, PROCESSED } from '../contract/constants'

export default {
  namespaced: true,
  state: {
    ...initialState,
    contracts: null
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_COMPANY_CONTRACTS(state, payload) {
      state.contracts = payload
    }
  },
  actions: {
    async fetchCompanyContracts({ commit }, vatNumber) {
      commit('SET_LOADING', true)
      const response = await api.companyContracts(vatNumber)
      if (response.data) commit('SET_COMPANY_CONTRACTS', response.data)
      commit('SET_LOADING', false)
    }
  },
  getters: {
    processedContracts(state) {
      return state.contracts?.filter((c) => c.status.value === PROCESSED) || []
    },
    waitingForSignatureContracts(state) {
      return state.contracts?.filter((c) => c.status.value === WAITING_FOR_SIGNATURE) || []
    },
    grossPremium(state, getters) {
      let total = 0
      getters.waitingForSignatureContracts.concat(getters.processedContracts).forEach((contract, gross_premium) => {
        total += contract.gross_premium
      })
      return total.toFixed(0)
    },
    insurerCommission(state, getters) {
      let total = 0
      getters.processedContracts.forEach((contract) => {
        total += parseFloat(contract.insurer_commission ? contract.insurer_commission : 0)
      })
      return total.toFixed(0)
    }
  }
}
